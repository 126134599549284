// CMS Page: Contact Us

.section--contact-content {
    @extend .full-vh;

    padding: 55px 0 70px;

    // Widget
    .widget {
        background-color: $white;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
        padding: 10px 25px 25px;
        font-size: 15px;
        color: #75818B;
        line-height: normal;

        & + .widget {
            margin-top: 25px;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
        }

        li {
            margin-bottom: 5px;
        }

        // Widget title
        .widget__title {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            border-bottom: 1px #EBEBEB solid;
            color: #009796;
            margin-bottom: 22px;
            padding: 12px 0;
        }

        h2 {
            @extend .widget__title;

            border: none;
            margin-bottom: 15px;
            padding: 0;
        }
    }
}

// Widget: Contact Form
.widget--contact-form {

    @media (max-width: 991px) {
        margin-bottom: 25px;
    }

    // Form: Marketo (Third party)
    .mktoForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: auto !important;
    }

    .mktoFormRow {
        display: flex;
        flex: 0 0 100%;

        // Quick Fix: First child is <style> element.
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            flex: 0 0 48%;
        }

        @media (max-width: 450px) {
            &:nth-child(4),
            &:nth-child(5) {
                flex: 0 0 100%;
            }
        }
    }

    // Call to action
    .mktoButtonRow {
        flex: 0 0 100%;
        margin: 30px 0 15px;

        @media (max-width: 450px) {
            margin-bottom: 20px;
        }
    }

    .mktoButtonWrap {
        display: flex;
        justify-content: center;
        margin-left: 0 !important;
    }

    .mktoButton {
        background: none #7DA419 !important;
        border-color: #7DA419 !important;
        border-radius: 4px !important;
        box-shadow: 0 0 8px rgba(101, 101, 101, .5) !important;
        min-width: 240px;
        height: auto !important;
        font-family: 'Montserrat', sans-serif;
        font-size: 15px !important;
        font-weight: 600;
        line-height: 19px !important;
        text-transform: uppercase;
        padding: 15px 25px !important;

        &:hover {
            background: none darken(#7DA419, 5%) !important;
            border-color: darken(#7DA419, 5%) !important;
        }
    }

    .mktoFormCol {
        flex: 1;
    }

    // Form group
    .mktoFieldWrap {
        width: 100%;
    }

    // Form label
    .mktoLabel {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 600 !important;
        color: #403A3A;
        margin-bottom: 5px;
        width: 100% !important;
    }

    // Form control
    .mktoField {
        font-family: 'Montserrat', sans-serif;
        border: 1px #C5CDD1 solid;
        border-radius: 5px;
        font-size: 13px !important;
        width: 100% !important;
        height: 33px !important;
        padding: 6px 10px !important;
    }

    textarea.mktoField {
        height: 100px !important;
        border-color: #EBEBEB;
    }
}

// Contact: FAQs Accordion
.faq-accordion {

    & + & {
        margin-top: 10px;
    }

    &__content {
        display: none;
        padding: 10px 0;
        font-size: 14px;

        // State: Opened
        .opened & {
            display: block;
        }
    }

    &:not(:last-child) {

        .faq-accordion__content {
            border-bottom: 1px #EBEBEB solid;
        }
    }
}

.faq-accordion__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:after {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        content: '+';
    }

    &:hover {
        cursor: pointer;
        color: $black;
    }

    .opened & {
        color: $black;
        font-weight: 600;

        &:after {
            content: '-';
        }
    }
}

//
// CMS Page: Contact Thanks
//

.cms-ce-contact-thanks,
.cms-contact-us-thank-you-for-contacting-us {


    /* Contact us Page Wrapper
    .page-wrapper {
        background-color: #f2fafa;
    }

     */
}

.section--contact-thanks {
    article {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 700px;
        text-align: center;

        figure {
            margin-bottom: 50px;
        }

        h1 {
            font-size: 32px;
        }
    }
}

// Pagebuilder Style Fix (as core files styles are not applying).
#html-body .contact-content-wrapper,
#html-body .contact-thanks-content-wrapper {
    width: 100% !important;
}
