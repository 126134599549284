// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Variables
//  _____________________________________________

$checkout-tooltip__hover__z-index: 99;

$indent__s: 0.5rem;
$checkout-tooltip-content__border-color: #444;
$checkout-tooltip-content__border-width: 0.125rem;
$checkout-tooltip-icon-arrow__font-size: 0.8125rem;

$checkout-tooltip-icon-arrow__font-size: 10px;
$checkout-tooltip-icon-arrow__left: -( 1rem + 1rem - $checkout-tooltip-content__border-width);

$checkout-tooltip-icon__color: #CCC;
$checkout-tooltip-icon__content: 'C';
$checkout-tooltip-icon__font-size: 24px;
$checkout-tooltip-icon__hover__color: #0564ac;

$checkout-tooltip-content__background-color: #F0F0F0;
$checkout-tooltip-content__border-color: #444;
$checkout-tooltip-content__border-width: 1px;
$checkout-tooltip-content__font-size: 1rem;
$checkout-tooltip-content__padding: 12px;
$checkout-tooltip-content__width: 270px;
$checkout-tooltip-content__active__border-color: darken($checkout-tooltip-content__border-color, 20%);

$checkout-tooltip-content-mobile-popup__width: 200px;
$checkout-tooltip-content-mobile__right: -($indent__s);
$checkout-tooltip-content-mobile__top: 30px + $checkout-tooltip-icon-arrow__font-size;

//
//  Common
//  _____________________________________________

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 1px;

  &._active {
    z-index: $checkout-tooltip__hover__z-index;

    .field-tooltip-content {
      display: block;
    }

    .field-tooltip-action {
      &:before {
        color: $checkout-tooltip-icon__hover__color;
      }
    }
  }

  .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .field-tooltip-action {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    color: #000;
    content: 'C';
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;

    &:before {
      padding-left : 1px;
    }

    &:focus {
      ._keyfocus & {
        z-index: $checkout-tooltip__hover__z-index;

        + .field-tooltip-content {
          display: block;
        }

        &:before {
          color: $checkout-tooltip-icon__hover__color;
        }
      }
    }
  }

  .field-tooltip-content {
    background: $checkout-tooltip-content__background-color;
    border: $checkout-tooltip-content__border-width solid $checkout-tooltip-content__border-color;
    border-radius: $checkout-tooltip-content__border-width;
    font-size: $checkout-tooltip-content__font-size;
    padding: $checkout-tooltip-content__padding;
    width: $checkout-tooltip-content__width;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2;

    &:before,
    &:after {
      position: left;
      font-size: 0.8125rem;
      color: #000;
      left: $checkout-tooltip-icon-arrow__left;
      top: $checkout-tooltip-content__padding;
      content: 'arrow';
      display: block;
      position: absolute;
      z-index: 3;
    }

    &:before {
      border-right-color: $checkout-tooltip-content__active__border-color;
    }

    &:after {
      border-right-color: $checkout-tooltip-content__background-color;
      width: 1px;
      z-index: 4;
    }
  }
}

//
//  Mobile
//  _____________________________________________

.modal-popup {
  .field-tooltip {
    .field-tooltip-content {
      width: $checkout-tooltip-content-mobile-popup__width;
    }
  }
}

@include media-breakpoint-up(xs) {
  .field-tooltip {
    .field-tooltip-content {
      right: $checkout-tooltip-content-mobile__right;
      top: $checkout-tooltip-content-mobile__top;
      left: auto;
      position: absolute;
    }
  }
}

//
//  Tablet
//  _____________________________________________

@include media-breakpoint-up(s) {
  .field-tooltip .field-tooltip-content {
    left: auto;
    right: -10px;
    top: 40px;
  }
  .field-tooltip .field-tooltip-content::before,
  .field-tooltip .field-tooltip-content::after {
    border: 10px solid transparent;
    height: 0;
    left: auto;
    margin-top: -21px;
    right: 10px;
    top: 0;
    width: 0;
  }
  .field-tooltip .field-tooltip-content::before {
    border-bottom-color: $checkout-tooltip-content__border-color;
  }
  .field-tooltip .field-tooltip-content::after {
    border-bottom-color: $checkout-tooltip-content__background-color;
    top: 1px;
  }
}
