$address-icon: "contacts";
$phone-icon: "local_phone";
$email-icon: "email";
$profession-icon: "work";
$ce-pro-icon: "chat_bubble";
$box-shadow-color: darken(adjust_hue($background-color, 17%), 12%);
$title-padding: 10px;
$info-background-color: linear-gradient(200deg, #2F8DB4, #2FA7AF);
$title-border-color: #D7EAEF;
$button-color: #0564AC;
$course-border-color: #91AC4E;

body.info-menu-opened {
    overflow-y: hidden;
}

/* Utility Classes */
.make-full-width-container {
    width: 100%;
    max-width: none;
}

//
//  Account Pages:  Default styles
//

.account {

    // Page Header
    .page-header {

        & {
            @include media-breakpoint-up(lg) {
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }

        .header.content {
            @extend .make-full-width-container;
        }
    }

    // Page Footer
    .page-footer {
        .container {
            @extend .make-full-width-container;
        }

        .footer__copyright {
            @include media-breakpoint-up(lg) {
                padding-right: 1rem;
            }
        }
    }
}


//
//  User Account Page
//

.my-account {
    @include media-breakpoint-down(lg) {
        .container-fluid {
            .row {
                .account-column.rlms-data {
                    padding: 50px 32px 0 32px;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .container-fluid {
            padding: 0;

            .row {
                width: 100%;
                margin: 0;
                overflow-x: hidden;

                .account-column {
                    padding: 30px 20px 30px 20px;

                    .greeting-container {
                        max-width: 100%;
                        padding-bottom: 25px;
                        border-bottom: 1px solid rgba(255, 255, 255, .3);

                        .greeting {
                            margin-left: 0px;

                            .user-display-name {
                                font-size: 24px;
                                font-weight: 300;
                                line-height: 25px;
                            }
                        }

                        .greeting-icon {
                            margin-left: auto;
                        }
                    }

                    .breadcrumb {
                        display: none;
                    }

                    &.info {
                        padding: 12px 20px 100px 20px;
                    }

                    &.rlms-data {
                        margin-top: -100px;

                        .license-container, .transcript-container {
                            .courses-title {
                                color: white;
                                font-weight: 300;
                                background-color: transparent;
                            }

                            .horizontal-bar.first-bar:after {
                                border-bottom: none;
                            }

                            #licenses-ajax-response, #transcripts-ajax-response {
                                padding: 0;
                                background-color: transparent;
                                box-shadow: none;

                                .table {
                                    .table-col-header, .license-row, .transcript-row {
                                        grid-template-columns: 1fr;
                                    }

                                    .table-col-header {
                                        display: none;
                                    }

                                    .license-row, .transcript-row {
                                        margin-bottom: 16px;
                                        padding: 28px 24px 12px 24px;
                                        background-color: white;
                                        border-bottom: 2px solid rgba(198, 223, 233, 0.6);
                                        border-radius: 3px;

                                        .mobile-label {
                                            display: block;
                                            line-height: 16px;
                                            font-weight: 500;
                                            color: #0A2C46;
                                            text-transform: uppercase;
                                        }

                                        .col {
                                            margin-bottom: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .mobile-menu {
                    padding: 0 40px;

                    .greeting-icon {
                        width: 54px;
                        height: 54px;
                        align-items: center;
                        justify-content: space-around;
                        margin: 24px auto 40px 0;
                        padding: 24px;
                        color: black;
                        text-align: center;
                        border-radius: 50%;
                        background: #fff;

                        .material-icons {
                            color: #0564AC;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        position: relative;
    }
    //Global Account Page Styles
    .horizontal-bar:after {
        border-bottom: 1px solid $title-border-color;
        content: "";
        display: block;
        width: 100%;
        position: relative;
        top: 15px;
        z-index: 0;
    }

    .account-column {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    p {
        margin: 0;
    }

    // Left Info Section
    .account-column.info {
        background: $info-background-color;
        color: white;
        padding: 30px 54px 60px 54px;

        > div, .mobile-menu > div {
            position: relative;
            max-width: 350px;
            margin: 24px auto;
            padding-left: 0;

            p {
                margin-left: 30px;
                font-size: 14px;
                line-height: 26px;
            }

            p.breadcrumb-item {
                margin-left: 0;
                color: white;
            }
        }

        .breadcrumb {
            margin: 0 auto;
            padding: 0;
            font-size: 14px;
            background-color: transparent;
        }

        @media screen and (max-width: 991px) {
            .mobile-menu {
                position: absolute;
                height: calc(100vh - 4.0625rem);
                z-index: 5;
                background: $info-background-color;
                width: 100vw;
                margin: 0;
                max-width: 99999px;
                top: 0;
                overflow-y: scroll;
                left: 100vw;
                transition: 250ms all ease-in-out;
            }

            body.info-menu-opened & {
                .mobile-menu {
                    left: 0;
                }
            }
        }

        .greeting-container {
            .greeting {
                margin-left: 20px;
            }

            .greeting-icon {
                margin-left: 40px;
                padding: 15px;
                background: #FFFFFF;
                border-radius: 50%;
                color: black;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                .material-icons {
                    font-size: 30px;
                }
            }
        }

        .breadcrumb {
            margin: 0 auto;
            padding: 0;
            font-size: 14px;
            background-color: transparent;
        }

        .user-image {
            border-radius: 50%;
        }

        .user-ce-pro-benefits-container {
            ul {
                margin-bottom: 3rem;
                font-size: 14px;
                line-height: 26px;
            }
        }

        .user-name-address-container:before, .user-phone-container:before, .user-email-container:before, .user-professions-container:before, .user-ce-pro-benefits-container:before {
            float: left;
            font-family: 'Material Icons';
            font-weight: 400;
            font-style: normal;
            font-size: 20px;
            display: inline-block;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: 'liga';
            position: absolute;
            top: 5px;
        }

        .user-membership-container {
            .pro-icon, .basic-icon {
                position: absolute;
                height: auto;
            }

            .user-ce-pro-membership {
                display: none;
            }

            .pro-icon {
                display: none;
                width: 21px;
                padding-top: 2px;
            }

            .basic-icon {
                display: block;
                width: 17px;
                padding-top: 1px;
            }

            .user-basic-membership {
                display: block;

                .white-link {
                    color: white;
                    text-decoration: underline;
                }
            }

            &.hasCePro {
                .basic-icon, .user-basic-membership {
                    display: none;
                }

                .pro-icon, .user-ce-pro-membership {
                    display: block;
                }
            }
        }

        .user-name-address-container:before {
            content: $address-icon;
        }

        .user-phone-container:before {
            content: $phone-icon;
        }

        .user-email-container:before {
            content: $email-icon;
        }

        .user-professions-container:before {
            content: $profession-icon;
        }

        .user-ce-pro-benefits-container:before {
            content: $ce-pro-icon;
        }

        .user-name-address-container > p {
            margin-left: 25px;
        }

        .user-display-name, .label, .user-phone {
            font-weight: 500;
        }

        .button-container {
            text-align: center;
            margin-top: 45px;

            button {
                appearance: none;
                border-radius: 30px;
                border: 1px solid white;
                background: transparent;
                font-size: 14px;
                color: white;
                padding: 7px 35px;
                white-space: nowrap;
                text-transform: uppercase;
                transition: all .15s ease-out;

                &:hover {
                    background-color: $white;
                    color: #009796;
                    transition: all .15s ease-out;
                }
            }
        }
    }

    // Middle Section
    .rlms-data {
        padding: 50px 0 50px 32px;

        .left-title, .right-title {
            position: relative;
            text-transform: uppercase;
            background: $background-color;
            z-index: 2;
        }

        .left-title {
            float: left;
            padding-right: $title-padding;
            font-size: 18px;
            font-weight: 500;
        }

        .right-title {
            float: right;
            padding-left: $title-padding;
            font-size: 14px;
        }

        .hidden {
            display: none;
        }

        #licenses-ajax-response, #transcripts-ajax-response {
            .table {
                margin-bottom: 0;

                .table-col-header, .license-row, .transcript-row {
                    display: grid;
                    grid-template-columns: 38% 36% 26%;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        padding-left: 0;
                    }
                }

                .table-col-header {
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: #0A2C46;

                    .col {
                        white-space: nowrap;
                    }
                }

                .license-row, .transcript-row {
                    padding: 8px 0;
                    color: #666;

                    .mobile-label {
                        display: none;
                    }
                }
            }
        }

        .ajax-response {
            background: #FFFFFF;
            margin: 10px 0 36px 0;
            box-shadow: 0 3px 0 rgba($box-shadow-color, .6);
            padding: 20px 24px;
            font-size: 14px;
            border-radius: 4px;
        }

        .course-container {
            align-items: center;
            justify-content: space-between;
            border-left: 4px solid #91AC4E;
        }

        .course-text {
            flex-grow: 1;
        }

        .course-button {
            display: inline-block;
            font-size: 16px;
            border-radius: 5px;
            padding: 6px 40px;
            margin-top: 15px;
            background: transparent;
            text-transform: uppercase;
            color: $button-color;
            border: 2px solid $button-color;
        }

        @media screen and (min-width: 992px) {
            .start-course-button {
                margin-top: 0;
                background: $button-color;
                color: white;
                padding: 10px 60px;
            }
        }

        .continue-course-button {

        }
    }

    // Right Section: Relias Alt Brands for CE
    .account-column.alt-brands {
        @extend .relias__brands;
    }

    .cross-sell-courses {
        padding: 50px 24px 0 32px;
    }

    .order-details {
        padding: 10px;
        background: #FFFFFF;
        box-shadow: 0 3px 0 rgba(198, 223, 233, 0.6);
        font-size: 13px;
        display: grid;
        grid-template-areas: 'a b';
        margin: 30px 0;
        border-radius: 3px;

        a {
            font-size: 13px;
        }

        .order-title {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: bold;
            display: block;
            letter-spacing: 0;
            grid-column: 1 / 3;
        }

        .order-title, .order-id {
            color: #0659A2;
        }

        .order-receipt {
            grid-column: 1;
            text-transform: uppercase;
        }
    }
}

// My Account content row (Bootstrap's row element)
.row--my-account-content {
    @extend .full-vh;
}

//
// Relias' Alternate Brands for CE
// Use this to extend other classes
//

.relias__brands {

    .container,
    .container-fluid {
        @include media-breakpoint-between(md, lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    .section__header {
        margin-bottom: 2rem;
    }

    .section__title {
        font-size: 28px;
        line-height: 36px;
        color: #485865;
    }

    .ce-brand {
        margin-bottom: 1.75rem;

        @include media-breakpoint-between(md, lg) {
            flex: 0 0 47%;
        }

        .btn {
            @include media-breakpoint-down(xl) {
                min-width: 0;
                font-size: 15px;
            }
        }


    }

    .ce-brand__logo {
        flex-direction: column;

        & > img {
            max-width: 280px;
        }
    }

    .ce-brand--nurse-dot-com {
        .ce-brand__logo {
            & > img {
                max-width: 220px;
            }
        }
    }

    .ce-brand__caption {
        margin-top: 1rem;
    }
}




body > div.page-wrapper > header > div > a > img {
    //display: none;
}
