// UI Component: Button

.btn {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    min-width: 245px;
    padding: 12px 25px;

    &-primary {
        background-color: #7DA419;
        border-color: #7DA419;

        &:hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            background-color: darken(#7DA419, 5%);
            border-color: darken(#7DA419, 5%);
        }
    }

    &-secondary {
        background-color: #1979C3;
        border-color: #1979C3;

        &:hover {
            background-color: darken(#1979C3, 5%);
            border-color: darken(#1979C3, 5%);
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }



}

.btn-lg {
    min-width: 240px;
    padding: 16px 40px;

    &.btn-primary {
        box-shadow: 0 0 8px rgba(101, 101, 101, .5);
        text-transform: uppercase;
    }
}

