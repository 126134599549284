.page-footer {
    background: #0f3255;
    padding: 1rem 0;

    @include media-breakpoint-down(md) {
        text-align: center;
        padding: 1.5rem 0;
    }

    .container {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @media (max-width: 1199px) {
            max-width: none;
        }
    }

    .ce-footer-logo {
        margin-bottom: 0;

        a {
            display: inline-flex;
        }

        svg {
            width: 15rem;
            height: auto;

            @include media-breakpoint-up(md) {
                width: 20rem;
            }
        }
    }
}

.footer__copyright {
    color: #FFF;
    font-size: 13px;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin-top: .5rem;

    @include media-breakpoint-up(md) {
        margin-top: 0;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: right;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }

    & > span {
        display: block;
    }

    &-links {

        @include media-breakpoint-up(sm) {
            display: inline-flex;
        }

        a {
            color: $white;
            display: inline-block;
            margin-top: 0.5rem;

            &:not(:last-child) {

                &:after {
                    display: inline-block;
                    content: '|';
                    margin-right: 0.625rem;
                    padding-left: 0.625rem;
                }
            }
        }
    }
}

