// Hero Component

.hero {
    display: flex;
    align-items: center;
    background-color: #009796;
    min-height: 380px;

    @media (max-width: 600px) {
        .container {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

// Hero Article
.hero__article {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff;

    p {
        font-family: 'Montserrat', sans-serif;
        line-height: 24px;
    }

    .call-to-action {
        margin-top: 35px;

        .btn {
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            padding: 16px 40px;
            min-width: 240px;
            box-shadow: 0 0 8px rgba(101, 101, 101, .5);
            border-width: 2px;
            color: #fff;

            & + .btn {
                margin-left: 30px;
            }
        }

        .btn-primary {
            text-transform: uppercase;
        }

        .btn-outline {
            background-color: transparent;
            border: 2px #fff solid;

            &:hover {
                background-color: #fff;
                color: #009796;
            }
        }
    }
}

// Hero Content
.hero__content {
    flex: 1;
    padding: 60px 0;

    @media (max-width: 1199px) {
        max-width: 700px;
        padding: 30px 0;
    }
}

// Hero Title
.hero__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 43px;
    line-height: 53px;

    & + p {
        margin-top: 16px;
    }

    @include media-breakpoint-down(lg) {
        & {
            font-size: 35px;
            line-height: 45px;
        }
    }

    @include media-breakpoint-down(md) {
        & {
            font-size: 25px;
            font-weight: 500;
            line-height: 35px;
        }
    }

    @include media-breakpoint-down(sm) {
        & {
            font-size: 21px;
            line-height: 33px;
        }
    }
}

// Hero Subtitle
.hero__subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 19px;
    color: #015554;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }
}

// Hero Figure
.hero__figure {
    display: none;
    flex: 1;
    align-self: flex-end;
    margin: 0 0 -2px;
    padding: 25px 0 0 30px;

    & > img {
        max-width: 100%;
        height: auto;
    }

    @include media-breakpoint-up(xl) {
        display: block;
        text-align: right;
    }
}

@include media-breakpoint-down(md) {
    .hero__article {
        .call-to-action {
            .btn {
                min-width: 0;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .hero__article {
        .call-to-action {
            display: flex;
            flex-direction: column;

            .btn {
                padding: 14px 40px;

                & + .btn {
                    margin-top: 12px;
                    margin-left: 0;
                }
            }
        }
    }
}


#html-body .hero-wrapper {
    width: 100% !important;
}


