//
//  Lib -> Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal__background-color : #FFF ;
$modal__box-shadow : 0 0 12px 2px rgba(0, 0, 0, .35);

$modal-popup__indent-vertical : 5rem;
$modal-popup__padding : 2rem;
$modal-popup__width : 75%;
$modal-popup__z-index : 99 ;

$modal-slide__first__indent-left : 14.8rem;
$modal-slide__indent-left : 4.5rem;
$modal-slide__padding : 2.6rem;
$modal-slide__z-index : 100 ;

$modal-slide-header__padding-vertical : 2.1rem;

$modal-popup-confirm__width : 50rem;

$modal-popup-image-box__border-color : #CCC ;
$modal-popup-image-box__max-width : 78rem;

$modal-popup-image-box-preview__max-width : 600 + (2 * 1rem );
$modal-popup-image-box-preview-image__max-height : 54rem;

//
//  Utilities
//  ---------------------------------------------

@mixin lib-modal() {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s .3s, opacity .3s ease;
  transition: visibility 0s .3s, opacity .3s ease;

  &._show {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;

    .modal-inner-wrap {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  .modal-inner-wrap {
    background-color: $modal__background-color;
    box-shadow: $modal__box-shadow;
    opacity: 1;
    pointer-events: auto;
  }
}

@mixin lib-modal-popup() {
  z-index: $modal-popup__z-index;
  left: 0;
  overflow-y: auto;

  &.confirm {
    .modal-inner-wrap {
      max-width: $modal-popup-confirm__width;

      .modal-content {
        padding-right: 7rem;
      }
    }
  }

  &._show {
    .modal-inner-wrap {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .modal-inner-wrap {
    margin: $modal-popup__indent-vertical auto;
    width: $modal-popup__width;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-transition: -webkit-transform .2s ease;
    transition: transform .2s ease;

  }
}

//
//  Common
//  _____________________________________________

body {
  &._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}

//  Modals overlay
.modals-overlay {
  background: #999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.modal-slide,
.modal-popup {
  @include lib-modal;
}

.modal-slide {

  &._inner-scroll {
    .modal-inner-wrap {
      overflow-y: visible;
      display: flex;
      flex-direction: column;
    }

    .modal-header,
    .modal-footer {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .modal-content {
      overflow-y: auto;
    }

    .modal-footer {
      margin-top: auto;
    }
  }

  .modal-header,
  .modal-content,
  .modal-footer {
    padding: 0 $modal-slide__padding $modal-slide__padding;
  }

  .modal-header {
    padding-bottom: $modal-slide-header__padding-vertical;
    padding-top: $modal-slide-header__padding-vertical;
  }
}

.modal-popup {
  @include lib-modal-popup;

  //  If applied, switching outer popup scroll to inner
  &._inner-scroll {
    overflow-y: visible;

    .ie11 & {
      overflow-y: auto;
    }

    .modal-inner-wrap {
      max-height: 90%;

      .ie11 & {
        max-height: none;
      }
    }

    .modal-content {
      overflow-y: auto;
    }
  }

  .modal-header,
  .modal-content,
  .modal-footer {
    padding-left: $modal-popup__padding;
    padding-right: $modal-popup__padding;
  }

  .modal-header,
  .modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .modal-header {
    padding-top: $modal-popup__padding;
    padding-bottom: 0;
  }

  .modal-footer {
    margin-top: auto;
    padding-bottom: $modal-popup__padding;
    padding-top: $modal-popup__padding;
  }

  .modal-footer-actions {
    text-align: right;
  }
}