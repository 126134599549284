// Theme Variables
//
// Overriding Bootstrap default variables
// This file should be imported before the bootstrap.scss in styles.scss


// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1300px
);

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1200px
);

// Breadcrumbs
$breadcrumb-divider-color: #fff;
