@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "./normalize/normalize";
@import "./theme/_variables";
@import "./bootstrap/bootstrap";
@import "./mage/components/modals";
@import "./mage/components/modals_extend";
@import "./mage/components/tooltip";
@import "./mage/components/loaders";
@import "./global";

// UI Components
@import "../Magento_Theme/styles/buttons";

// Theme
@import "../Magento_Theme/styles/footer";
@import "../Magento_Theme/styles/header";
@import "../Magento_Theme/styles/hero";
@import "../Magento_Theme/styles/homepage";
//@import "../Magento_Theme/styles/owlcarousel";

// CMS: Page & Blocks
@import "../Magento_Theme/styles/cms/page/default";
@import "../Magento_Theme/styles/cms/page/contact";

@import "../Magento_Customer/styles/login";
//@import "../Magento_Customer/styles/register";
@import "../Magento_Customer/styles/forgotpassword";

//@import "../Magento_Catalog/styles/product";
//@import "../Magento_Catalog/styles/category";
//@import "../Magento_Checkout/styles/cart";
//@import "../Magento_Checkout/styles/minicart";
//@import "../Magento_Checkout/styles/checkout";
//@import "../Magento_Checkout/styles/checkout/modals";
@import "../Magento_Checkout/styles/authentication";

@import "../Relias_Theme/styles/my-account";
@import "../Relias_Theme/styles/modal";

//@import "../Magento_Paypal/styles/paypal";

@import "../ParadoxLabs_Subscriptions/styles/subscriptions";
