.login-wrapper {
    @extend .full-vh;

    display: flex;
    align-items: center;
    position: relative;
    background: #f3fafa;
    padding: 2rem 0;

    .login-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 32%;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .login-container {
        @include make-container();
        @include make-container-max-widths();
        z-index: 2;

        @include media-breakpoint-down(sm) {
            padding-left: 25px;
            padding-right: 25px;
        }

        .account-info {
            @include make-col-ready();
            display: none;

            .account-info-wrapper {
                background: linear-gradient(20deg, #5098c7 25%, #47a199 75%);
                color: #fff;
                padding: 2rem;

                .account-info-title {
                    font-weight: 700;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    margin-bottom: 1rem;
                }

                .account-info-list {
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0 0 0 1rem;

                        li {
                            font-size: 0.8rem;

                            &::before {
                                content: "\25CB";
                                color: #fff;
                                display: inline-block;
                                width: 1rem;
                                margin-left: -1rem;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(md) {
                @include make-col(4);
                display: flex;
            }
        }

        .account-login {
            @include make-col-ready();

            h1 {
                font-size: 2rem;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0;

                & + p {
                    font-size: 1rem;
                    margin: 0.3rem 0 1.5rem;
                }
            }

            .label {
                font-size: .95rem;
                font-weight: 500;
                line-height: normal;
            }

            .field {
                margin-bottom: 1.15rem;
            }

            input[type=text], input[type=password], input[type=email] {
                border: 1px solid #e8e9ea;
                padding: 0.5rem;
                width: 100%;

                &:focus {
                    border-color: darken(#e8e9ea, 20%);
                }

                &.mage-error {
                    border-color: $danger;
                }

                // Error Message right after input field
                & + .mage-error {
                    font-size: 14px;
                    color: $danger;
                }
            }

            .button-wrap {
                @include make-col-ready();
                margin-top: 2rem;
                margin-bottom: 2rem;

                button {
                    border-radius: 4px;
                    min-width: 280px;
                    padding: 0.8rem 1.5rem;
                    background-color: #2864a7;
                    color: #fff;
                    cursor: pointer;
                    border: 2px solid #2864a7;
                    box-shadow: 1px 1px 8px rgba(101, 101, 101, .45);
                    transition: all .2s ease-out;
                    text-transform: uppercase;

                    &:hover {
                        background-color: #7DA419;
                        border-color: #7DA419;
                        box-shadow: 1px 1px 8px rgba(101, 101, 101, .6);
                        color: #fff;
                        transition: all .2s ease-out;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                @include make-col(6);
                @include make-col-offset(1);
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }
}

// Logout Success Page
.customer-account-logoutsuccess {

    /* Page Main Container */
    .page-main {
        @extend .full-vh;

        padding: 100px 0 50px;
        text-align: center;

    }
}
