//
// CMS Page: Default/Common Styles
//

/*
 * Target all cms page ( Unique CSS class on body element )
 */

[class^="cms-"] {

    // Page Wrapper
    .page-wrapper {
        background-color: #F2F7FB;
    }

    // Breadcrumbs
    .breadcrumbs {
        display: none;
    }

    p {
        font-size: 15px;
    }

    .column {
        &.main {

            .page-title-wrapper {
                margin-bottom: 25px;
                padding: 40px 0 0px;

                @include media-breakpoint-down(sm) {
                    padding-left: 20px;
                    padding-right: 20px;
                }


            }
        }
    }
}

.cms-page-content {

    @include media-breakpoint-down(sm) {
        padding: 25px 20px!important;
    }

    .page-title-wrapper + & {
        @include media-breakpoint-down(sm) {
            padding: 0 20px 25px!important;
        }
    }

    .row-full-width-inner {
        background-color: $white;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
        padding: 35px;

        @include media-breakpoint-down(md) {
            padding: 35px 25px;
        }
    }
}

/*
 * CMS Page: Terms of Service
 */

.cms-terms-of-service {

    .page-title-wrapper {

        .row-full-width-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }

    // Page Title
    .page-title {
        font-size: 2rem;

        @include media-breakpoint-down(md) {
            font-size: 1.8rem;

            & + p {
                font-size: 14px;
            }
        }

    }

    h2 {
        font-size: 1.25rem;
        font-weight: 600;
        color: #00a499;
    }

    h3 {
        font-size: 1.1rem;
        font-weight: 600;
    }
}



