.load.indicator {
  background-color: rgba(255, 255, 255, .5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &:before {
    content: attr(data-text);
    text-align: center;
    vertical-align: baseline;
    background: transparent url('./../images/loader-2.gif') no-repeat 50% 50%;
    border-radius: 5px;
    width: 160px;
    height: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
  position: absolute;

  > span {
    display: none;
  }
}

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, .5);

  .loader {
    > img {
      bottom: 0;
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 100;
    }

    > p {
      display: none;
    }
  }
}

body {
  > .loading-mask {
    z-index: 9999;
  }
}

._block-content-loading {
  position: relative;
}