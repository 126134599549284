
//
// Extend this Layout Class
// in other page level class (e.g '.customer-subscriptions-index', '.vault-cards-listaction')
// to get the same Layout( Main Content & Right Sidebar ) and styles for UI components (Page title).
//

.layout__account-default {

    // Page Title Wrapper
    .page-title-wrapper {
        max-width: 1440px;

        @media (max-width: 1480px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        // Page Title
        .page-title {
            margin-bottom: 2rem;
        }
    }

    // Layout
    .columns {
        @extend .full-vh;

        max-width: 1440px;
        padding-bottom: 7rem;

        @media (max-width: 1480px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .column {

        &.main {
            position: relative;
            display: flex;
            align-items: flex-start;
            width: 100%;

            & {
                @media (max-width: 1299px) {
                    flex-direction: column;
                    align-items: initial;
                }
            }
        }

        .section--main-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: $white;
            box-shadow: 0 1px 10px -6px rgba(0, 0, 0, .25);
            min-height: 350px;
            margin-bottom: 4rem;
            padding: 30px 34px 40px 34px;

            & {
                @media (min-width: 1300px) {
                    margin-right: 2rem;
                }
            }

            .subtotal-notice {
                margin-top: auto !important;
            }
        }

        // Account Page Sidebar: Relias Brands
        .account__sidebar {
            flex: 0 0 300px;

            &.brands {
                @extend .relias__brands;
            }
        }
    }

    // Content Container
    .container-fluid {
        display: flex;
        max-width: 1440px;
    }
}


//
// Page: Subscription
//

.customer-subscriptions-index {

    /* Extending the Account's page default layout */
    @extend .layout__account-default;
}

//
// Page: Stored Payment Methods
//

.vault-cards-listaction {

    /* Extending the Account's page default layout */
    @extend .layout__account-default;
}





.customer-subscriptions-index,
.vault-cards-listaction,
.customer-subscriptions-view,
.customer-subscriptions-edit {
   .page-title-wrapper {
        max-width: 1440px;
        margin: auto;

        h1 {
            margin-bottom: 10px;
            padding-top: 40px;
            font: 400 26px/30px 'Montserrat', sans-serif;
            @include media-breakpoint-down(lg) {
                text-align: center;
            }
        }
    }
    .columns {
        display: flex;
        margin: auto;
        column-gap: 32px;

        .sidebar.sidebar-main {
            display: none;
        }
        .column.main {


            @include media-breakpoint-down(sm) {
                padding-bottom: 100px;
            }

            .toolbar.toolbar-products {
                display: grid;
                grid-template-areas: 'a b';
                margin-bottom: 26px;
                padding-bottom: 12px;
                border-bottom: 1px solid #e8e9ea;
                @include media-breakpoint-down(sm) {
                    grid-template-areas: 'a';
                    .toolbar-amount {
                        text-align: center;
                    }
                    .field.limiter {
                        margin: 0 !important;
                    }
                }
                .toolbar-amount {
                    font-size: 16px;
                    line-height: 16px;
                    color: #0a2c46;
                    white-space: nowrap;
                }
                .field.limiter, .toolbar-sorter.sorter {
                    display: flex;
                    align-items: center;
                    column-gap: 7px;
                    font-size: 14px;
                    label {
                        margin: 0;
                        white-space: nowrap;
                    }
                }
                .field.limiter {
                    margin: 0 0 0 auto;
                    .control {
                        select {
                            padding: 0 8px;
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }
                .toolbar-sorter.sorter {
                    select {
                        padding: 0 10px;
                        font: 400 14px/38px 'Montserrat', sans-serif;
                        color: #0a2c46;
                        background-color: white;
                        border: 1px solid #e8e9ea;
                        border-radius: 2px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        cursor: pointer;
                    }
                }
                .filter-and-sort {
                    display: none;
                }
            }
            #my-subscriptions-table, #subscription-item-table, #subscription-log-table {
                display: block;
                caption.table-caption {
                    display: none;
                }
                @include media-breakpoint-down(sm) {
                    thead {
                        display: none !important;
                    }
                    tbody {
                        tr {
                            grid-template-columns: auto !important;
                            row-gap: 8px;
                            padding: 20px 30px !important;
                            border: 1px solid #e8e9ea;
                            td.actions {
                                margin-top: 12px;
                            }
                        }
                    }
                }
                thead, tbody {
                    display: block;
                    tr {
                        display: grid;
                        margin-bottom: 14px;
                        th, td {
                            padding: 0;
                            border: none;
                            font: 500 14px/16px 'Montserrat', sans-serif;
                            .mobile-label {
                                display: none;
                                @include media-breakpoint-down(sm) {
                                    display: inline-block;
                                    width: 120px;
                                    font-weight: 500;
                                    color: #0A2C46;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
                thead {
                    tr {
                        th {
                            color: #0A2C46;
                            text-transform: uppercase;
                        }
                    }
                }
                tbody {
                    tr {
                        align-items: center;
                        padding: 8px 0;
                        td {
                            color: #666;
                            &.actions {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                row-gap: 8px;
                                font-weight: 500;
                                font-size: 13.5px;
                                letter-spacing: 0.2px;
                                text-transform: uppercase;

                                a {
                                    &:hover {
                                        text-decoration: none;
                                    }
                                    &.edit {
                                        color: #48a199;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #my-subscriptions-table {
                thead, tbody {
                    tr {
                        grid-template-columns: 15% 20% repeat(5, 11%) 10%;
                    }
                }

            }
            .subtotal-notice,
            .change-notice {
                position: static;
                margin: 2rem 0 .65rem;
                bottom: 0;
                font-size: 14px;
                color: #666;
            }
            // EDIT STYLES
            .form-subscription-edit {
                .legend {
                    font-size: 18px;
                }
                .card {
                    border: none;
                    label {
                        font-size: .8125rem;
                    }
                    select {
                        margin-left: .125rem;
                        font-size: .8125rem;
                        line-height: 45px;
                        border: 1px solid #eee;
                        border-radius: .1875rem;
                    }
                }
                .actions-toolbar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .primary {
                        order: 1;
                        button.save {
                            padding: 8px 24px;
                            font-size: 14px;
                            font-weight: 300;
                            letter-spacing: .75px;
                            text-transform: uppercase;
                            color: #fff;
                            background-color: #0564ac;
                            border: 1px solid #0564ac;
                            border-radius: 2px;
                            cursor: pointer;
                            transition: .2s color, .2s background-color;
                            &:hover {
                                color: #0564ac;
                                background-color: white;
                            }
                        }
                    }
                    .secondary {
                        .back {
                            text-transform: uppercase;
                            font-size: 13.5px;
                            letter-spacing: .25px;
                        }
                    }
                }
            }
        }
    }
}
.customer-subscriptions-view {
    .page-title-wrapper {
        display: grid;
        grid-template-columns: auto auto 1fr;
        @include media-breakpoint-down(sm) {
            display: block;
        }
        .order-status {
            display: flex;
            align-self: end;
            justify-content: left;
            padding: 0 0 8px 12px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: .2px;
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }
        .actions-toolbar.subscription-actions-toolbar {
            padding-left: 20px;
            align-items: end;
            display: grid;
            padding-bottom: 8px;
            @include media-breakpoint-down(sm) {
                padding: 0 12px;
            }
            .actions {
                display: flex;
                justify-content: left;
                .action.edit, .action.pause {
                    color: white;
                    padding: 7px 16px;
                    font: 400 13px/18px 'Montserrat', sans-serif;
                    border-radius: 3px;
                    text-transform: uppercase;
                    letter-spacing: .2px;
                }
                .action {
                    &.edit {
                        background-color: #0564AC;
                    }
                }
            }
        }
    }
    .subscription.cart.table-wrapper {
        #subscription-item-table {
            thead, tbody {
                tr {
                    grid-template-columns: 1fr repeat(2, 12%);
                    th.qty, td.qty {
                        display: none;
                    }
                    td.item {
                        a.product-item-photo {
                            display: none;
                        }
                        .product-item-details {
                            font-weight: 500;
                        }
                    }
                    &.item-actions {
                        display: none;
                    }
                }
            }
        }
    }
    .block-subscription-info {
        margin-bottom: 24px;
        .block-title {
            margin-bottom: 12px;
            padding-bottom: 4px;
            border-bottom: 1.5px solid #edeeef;
            strong {
                font: 500 14px/18px 'Montserrat', sans-serif;
                text-transform: uppercase;
            }
        }
        .block-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 20px;
            .box-address-billing {
                padding-right: 30px;

            }
            .box-address-billing, .box-schedule {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                    width: 100%;
                }
                .box-title {
                    span {
                        font: 500 14px/16px 'Montserrat', sans-serif;
                    }
                }
                .box-content {
                    width: 300px;
                    border: 1px solid #edeeef;
                    padding: 14px 24px;
                    border-radius: 3px;
                    strong {
                        span {
                            font-weight: 500;
                        }
                    }
                    address {
                        font-size: 14px;
                        line-height: 22px;
                        color: #666;
                        margin-bottom: 4px;
                        a {
                            color: #0564ac;
                        }
                    }
                    p.description {
                        font: 500 16px/18px 'Montserrat', sans-serif;
                    }
                    dl.item-options {
                        display: grid;
                        grid-template-areas: 'a b';
                        font-size: 14px;
                        line-height: 22px;
                        color: #666;
                        margin-bottom: 4px;
                        dt {
                            width: max-content;
                            margin-right: 12px;
                            font-weight: 500;
                            color: #212529;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .subscription.logs.table-wrapper {
            width: 100%;
            #subscription-log-table {
                thead, tbody {
                    tr {
                        grid-template-columns: 1fr 20% 30%;
                    }
                }
            }
        }
    }
}
.table-credit-cards .table-caption{
    display: none;
}
