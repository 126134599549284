//
//  Page Header
//

// Variables
$page-header-fix-height: 4.56rem;
$page-header-fix-height-mobile: 4.1rem;


.page-header-staticplaceholder {
    display: none;

    @include media-breakpoint-up(lg) {
        height: $page-header-fix-height;
        display: block;
    }
}

.page-header {
    display: flex;
    min-height: $page-header-fix-height-mobile;
    padding: 16px 0;
    background: rgba(255, 255, 255, .9);
    box-shadow: 0 2px 7px rgba(0, 0, 0, .12);

    @include media-breakpoint-up(lg) {
        position: fixed;
        top: 0;
        width: 100%;
        height: $page-header-fix-height;
        z-index: 4;
    }

    .header.content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;

        .logo {
            display: inline-block;
            margin-top: 0;


            img {

                @include media-breakpoint-down(md) {
                    width: auto;
                    max-width: 100%;
                    height: 26px;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .panel.wrapper {
                margin: 1.3rem 1.25rem 0 0 !important;

                .user-name {
                    display: none !important;
                }

                .panel.header {
                    border-radius: 3px;
                    box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, .3);
                }
            }

        }

        .find-your-profession-desktop-container {
            display: none;
        }

        .login-link {
            line-height: 0.8125rem;

            a {
                display: inline-block;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                text-transform: uppercase;
                text-decoration: none;
                color: $black;
                vertical-align: top;

                &:after {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background:url('./../Magento_Theme/images/i-account.svg') no-repeat;
                    background-size: contain;
                    margin-left: 5px;
                    content:'';
                }
            }

            @include media-breakpoint-down(lg)  {
                a {
                    font-size: 15px;

                    &:after {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            @include media-breakpoint-down(sm)  {
                a {
                    font-size: 14px;
                }
            }

        }
    }
}


// Site Navigation
.site-navigation {
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-down(xl) {
        &:before {
            position: fixed;
            display: none;
            top: $page-header-fix-height-mobile;
            right: 0;
            width: 100%;
            height: calc(100% - #{$page-header-fix-height-mobile});
            background-color: rgba(0, 0, 0, .5);
            content: '';
            padding: 30px 20px;
            z-index: 99;
        }

        .has-overlay & {
            &:before {
                display: block;
            }
        }
    }

    .nav--main {
        display: inline-flex;
        list-style-type: none;
        margin-bottom: 0;

        @media (max-width: 1199px) {
            position: fixed;
            display: block;
            top: $page-header-fix-height-mobile;
            right: 0;
            width: 90%;
            max-width: 380px;
            height: calc(100% - #{$page-header-fix-height-mobile});
            background-color: #fff;
            padding: 30px 20px;
            transform: translateX(100%);
            transition: all .15s ease-out;
            z-index: 100;

            &.opened {
                box-shadow: 0 2px 4px #463f5f;
                transform: translateX(0);
                transition: all .15s ease-out;
            }
        }

        &:after {
            display: none;

            @media (max-width: 1199px) {
                position: absolute;
                bottom: 0;
                right: 0;
                content: '';
                background: linear-gradient(to bottom right, transparent 50%, #00A499 50%);
                height: 60px;
                width: 80%;
                display: block;
            }
        }

        & > li {
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $black;
            margin-right: 34px;

            @media (max-width: 1199px) {
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px #ddd solid;
            }

            a {
                color: inherit;

                &:hover {
                    color: #009796;
                    text-decoration: none;
                }
            }
        }


    }
}

.nav--user {

    .dropdown-menu {
        top: calc(100% + 10px);
        left: auto;
        right: 0;
        min-width: 12rem;
        padding: 1.5rem 1.875rem;
        box-shadow: 0 10px 30px rgba(0, 0, 0, .4);

        &:before,
        &:after {
            position: absolute;
            right: 2rem;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            transform: translateY(-8px);
        }

        &:before {
            top: 0;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            border-bottom-color: rgba(0,0,0,.25);
        }

        &:after {
            top: 1px;
            border-width: 0 0.5rem 0.5rem 0.5rem;
            border-bottom-color: #fff;
        }

        a {
            font-size: 14px;
            font-weight: 500;
            padding: 0;

            &:hover {
                background-color: transparent;
                color: #0564ac;
            }
        }
    }
}

.user-nav-toggle {
    display: flex;
    align-items: center;
    color: $black;
    font-size: .975rem;
    font-weight: 500;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }

    &:before {
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('./../Magento_Theme/images/i-account-circle.svg') no-repeat;
        background-size: contain;
        margin-right: 5px;
        content: '';

        @include media-breakpoint-down(sm) {
            margin-right: 2px;
        }

        @media (max-width: 370px) {
            display: none;
        }

    }

    &:hover {
        color: #009796;
        text-decoration: none;
    }

    .greeting {
        display: inline-block;
        margin-right: 4px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .nav--user__name-wrap {
        display: inline-flex;
    }

    .nav--user__name {
        display: inline-block;

        @include media-breakpoint-down(sm) {
            max-width: 62px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

// Navigation Toggle
.toggle-navigation {
    display: inline-block;
    order: 5;
    margin-left: 15px;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(xl) {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 8px;
        padding-right: 3px;
    }

    .icon-wrap {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;

        & > svg {
            width: 19px;
            height: 16px;
            transform-origin: 50% 50%;
            transition: all .13s ease-out;
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            font-family: 'Material Icons';
            content: '\e5cd';
            font-size: 26px;
            line-height: normal;
            transform-origin: 50% 50%;
            transform: scale(0) translate(-50%, -50%);
            transition: all .13s ease-out;
        }
    }

    &.clicked {
        .icon-wrap {
            & > svg {
                transform: scale(0);
                transition: all .13s ease-out;
            }

            &:after {
                transform: scale(1) translate(-50%, -50%);
                transition: all .13s ease-out;
            }
        }
    }
}

html.nav-open {
    .page-header-staticplaceholder {
        height: 4.0625rem;
        display: block;
    }

    .page-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .nav-sections {
        transition: 0.25s;
        right: 0;
    }
}

body.ajax-loading {
    .page-header {
        position: relative;

        .header.content {
            .minicart-wrapper {
                position: initial;

                > .action.showcart .counter {
                    position: initial;

                    .loading-mask {
                        position: fixed !important;
                    }
                }
            }
        }
    }

    .page-header-staticplaceholder {
        height: 0;
    }
}

.breadcrumbs {
    display: inline-block;
    margin: 1.8125rem 0 1rem;
    color: #e9e9e9;
    font-weight: 400;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0.8125rem;
        line-height: 1;

        li {
            display: inline-block;

            &:after {
                content: '/';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }

            a {
                color: #e9e9e9;
                text-decoration: none;
            }

            strong {
                font-weight: inherit;
            }
        }
    }
}
