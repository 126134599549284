.authentication-dropdown {
  box-sizing: border-box;
  @include media-breakpoint-up(md) {
    background-color: #FFF;
    border: 1px solid #CCC;
    -webkit-transform: scale(1, 0);
    -webkit-transform-origin: 0 0;
    -webkit-transition: -webkit-transform linear .1s, visibility 0s linear .1s;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear .1s, visibility 0s linear .1s;
    visibility: hidden;
    width: 100%;
  }
  .modal-inner-wrap {
    padding: 1rem;
  }
  &._show {
    z-index: 101;
    -webkit-transform: scale(1, 1);
    -webkit-transition: -webkit-transform linear .1s, visibility 0s linear 0s;
    transform: scale(1, 1);
    transition: transform linear .1s, visibility 0s linear 0s;
    visibility: visible;
  }
}
.authentication-wrapper {
  float: right;
  margin-top: -1.5*2rem;
  max-width: 50%;
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(md) {
    column-width: 200px;
    text-align: right;
  }
  ._has-auth-shown & {
    z-index: 100;
  }
}
.popup-authentication {
  .modal-inner-wrap {
    height: auto;
    min-height: auto;
    margin: 2rem;
    min-width: unset;
    width: calc(100% - 4rem);
    @include media-breakpoint-up(md) {
      min-width: 600px;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .block-authentication {
    display: flex;
    flex-direction: column;
    text-align: center;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      .block[class],
      .form-login,
      .fieldset,
      .block-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        p {
          margin-top: 0;
        }
      }
    }
    .block-title {
      font-size: 1.5rem;
      line-height: 1.25;
      border-bottom: 0;
      margin-bottom: 1rem;
      strong {
        font-weight: 600;
        font-size: 1.125rem;
      }
    }
    .field {
      .label {
        font-weight: 500;
      }
      input {
        background-color: #FFF;
        border: 1px solid #CCC;
        padding: 0.5rem 1rem;
      }
    }
    .actions-toolbar {
      margin-top: 0.5rem;
      margin-bottom: 0;
      .action {
        &.primary,
        &.action-login {
          float: none;
          border: 1px solid #FFF;
          border-radius: 0.25rem;
          background-color: #0564ac;
          font-size: 0.75rem;
          text-transform: uppercase;
          padding: 0.8125rem;
          line-height: 1;
          color: #FFF;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;
        }
      }
      > .primary {
        display: inline;
      }
      > .secondary {
        padding-top: 1rem;
        .action {
          font-size: 0.8125rem;
          color: #444;
        }
      }
    }
    .action.action-register,
    .block[class] {
      margin: 0;
      @include media-breakpoint-up(md) {
        box-sizing: border-box;
        float: left;
        padding: 0.25rem 1.5rem 0 0;
        width: 50%;
      }
      ul {
        list-style: none;
        padding-left: 0;
        font-size: 0.8125rem;
        line-height: 2;
        margin-top: 0;
      }
      .field {
        .control,
        .label {
          float: none;
          width: auto;
        }
      }
      & + .block {
        border-top: 0;
        margin-top: 2rem;
        padding-top: 2rem;
        position: relative;
        border-top: 1px solid #CCC;
        @include media-breakpoint-up(md) {
          border-left: 1px solid #CCC;
          border-top: 0;
          border-top: 0;
          margin: 0;
          padding: 0.25rem 0 0 2rem;
        }
        &:before {
          height: 2.25rem;
          line-height: calc(2.25rem - 2px);
          margin: calc(-(2.25rem / 2 + 1px)) 0 0 calc(-(2.25rem / 2));
          min-width: 2.25rem;
          background: #FFF;
          border: 1px solid #0564ac;
          border-radius: 50%;
          box-sizing: border-box;
          color: #0564ac;
          font-size: 0.8125rem;
          font-weight: 600;
          content: attr(data-label);
          display: inline-block;
          left: 50%;
          letter-spacing: normal;
          padding: 0 .2rem;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: -1rem;
          left: calc(50% - 1rem);
          @include media-breakpoint-up(md) {
            left: -1.125rem;
            top: calc(50% - 1rem);
          }
        }
      }
    }
  }
}
