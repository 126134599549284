@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500&display=swap');

$business-blue: #0564ac;
$teal: #00a499;

body {
  // Replace '.main-cont' with main container of actual page
  .main-cont.modal-open {
    height:     100vh;
    overflow-y: hidden;
  }

  // Changing Widget Styles
  .modals-overlay {
    opacity: 1;
    background-color: rgba(34, 34, 34, .8);
  }

  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: white;
  }

  .modal-popup.modal-slide .modal-footer {
    border-top: none;

    .lg-blue-btn {
      text-transform: uppercase;
    }
  }

  .modal-popup {
    .modal-inner-wrap {
      max-width: 650px;
      width: 85%;
      margin: 95px auto;
      padding: 30px 32px;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      color: #0A2C46;
      letter-spacing: .25px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4px;

      @include media-breakpoint-down(md) {
          width: 100%;
          margin-top: 0;

          .modal-content {
              .tabs-cont, #oneForm, #threeForm, #fourForm, .info-request-link, .modal-footer {
                  padding: 0;
              }
              .modal-footer {
                  display: grid;
                  padding: 0;

                  .cancel-btn {
                      position: unset;
                      order: 1;
                      width: 100%;
                      margin-top: 16px;
                  }

                  .action.save {
                      width: 100%;
                      padding: 21px 0;
                      line-height: 16px;
                  }
              }
          }
      }

      .modal-content {
        padding: 0;
        padding: 0;
        border: none;
        border-radius: 0;
      }

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 20px 0;
        border-bottom: 1px solid #edeeef;
        background-color: white;

        h1.modal-title {
          padding-bottom: 0;
          font-size: 16px;
          font-weight: 400;
        }

        .action-close {
          position: relative;
          padding: 0;
          border: none;
          font-weight: 400;
          font-size: 30px;
          left: unset;
          right: 0;
          top: unset;
          color: #3399CC;
        }

        .action-close:hover {
          color: #3399CC;
          background-color: transparent;
        }
      }
    }
    .modal-title {
      border:none;
    }
  }

  // END Widget Styles

  .custom-modal {
    .modal-body {
      padding: 38px 0px;

      .tabs-cont {
        display:         flex;
        justify-content: space-between;
        margin-bottom:   40px;
        padding:         0 56px;

        .tab {
          padding-bottom: 2px;
          cursor:         pointer;

          a {
            color: #222222;
          }

          a:hover {
            text-decoration: none;
          }
        }

        .tab.active {
          border-bottom: 2px solid $business-blue;

          a {
            color:         $business-blue;
          }
        }
      }

      .tab-content {
        display: none;
      }

      .tab-content.active {
        display: block;
      }

      .prof-row, .transcript-row, .license-row {
        display:       grid;
        column-gap:    20px;
        margin-bottom: 22px;
        color:         #666666;


        i.material-icons {
          font-size: 20px;
          color:     $business-blue;
          cursor:    pointer;
        }
      }

      #oneForm {
        padding: 0 56px;

        .field-group {
          display:               grid;
          grid-template-columns: 43% 1fr;
          column-gap:            16px;
        }
      }

      #twoForm {
        padding: 0 56px;

        .col-titles {
          grid-template-columns: 52% 1fr;
        }

        .prof-row {
          grid-template-columns: 52% 1fr 20px 20px;
        }

        .prof-form-cont {
          border-top: 1px solid #edeeef;

          .add-prof-btn {
            display: none;
          }

          .add-prof-btn.active {
            display:       flex;
            column-gap:    12px;
            margin-bottom: 45px;
            padding-top:   40px;
            color:         $business-blue;
            cursor:        pointer;

            .add-cont {
              display:          grid;
              align-items:      center;
              justify-content:  center;
              width:            20px;
              height:           20px;
              border-radius:    50%;
              background-color: $business-blue;

              i {
                font-size: 18px;
                color:     white;
              }
            }

            span {
              font-size: 14px;
            }
          }

          .add-prof-form {
            display: none;
          }

          .add-prof-form.active {
            display: block;

            .prof-form-title {
              padding:       40px 0 18px 0;
              border-bottom: 1px solid #edeeef;
            }

            form {
              display:    grid;
              row-gap:    20px;
              margin-top: 20px;

              .checklist {
                .field-label {
                  margin-bottom: 20px;
                }

                .check-field {
                  display:       inline-flex;
                  width:         40%;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }

      #threeForm {
        padding: 0 56px;
        row-gap: 22px;

        .field-group-label {
          margin-top: 18px;
          font-size:  13.5px;
          color:      #00a499;
        }

        .aqua {
          color: #00A499;
        }
      }

      .modal-note {
        margin-bottom: 35px;
        padding-top:   34px;
        font-size:     14px;
        border-top:    1px solid #edeeef;
      }
    }

      #fourForm {
          padding: 0 56px;
      }

    .modal-footer {
      position:        relative;
      justify-content: unset;
      padding:         0 56px;
      border:          none;

      .cancel-btn {
        position:       absolute;
        font-size:      15px;
        letter-spacing: .25px;
        border:         none;
        background:     transparent;
      }

      .lg-blue-btn {
        margin:         auto;
        padding:        15px 62px;
        font-size:      15px;
        color:          white;
        letter-spacing: .25px;
        background:     #0564ac 0% 0% no-repeat padding-box;
        border:         none;
        border-radius:  4px;
      }
    }
  }


  // Transcripts Modal
  .overlay[aria-label="transcriptModalBtn"] {
    .custom-modal {
      max-width: 975px;

      .modal-body {
        .col-titles {
          grid-template-columns: 32% 22% 1fr;
        }

        .transcript-row {
          grid-template-columns: 32% 22% 1fr 20px ;
        }
      }

      .modal-footer {
        display:      block;
        padding-left: 0;

        .cancel-btn {
          margin-left: 0;
        }
      }
    }
  }

  // License Modal
  .overlay[aria-label="licenseModalBtn"] {
    .custom-modal {
      max-width: 975px;

      .modal-body {
        .col-titles {
          grid-template-columns: 32% 13% 12% 1fr;
        }

        .license-row {
          grid-template-columns: 32% 13% 12% 1fr 20px 20px;
        }

        .license-form-cont {
          border-top: 1px solid #edeeef;

          .add-license-btn {
            display: none;
          }

          .add-license-btn.active {
            display:       flex;
            align-items:   center;
            column-gap:    12px;
            margin-bottom: 45px;
            padding-top:   40px;
            color:         $business-blue;
            cursor:        pointer;

            .add-cont {
              display:          flex;
              align-items:      center;
              justify-content:  center;
              width:            20px;
              height:           20px;
              margin-right:     12px;
              border-radius:    50%;
              background-color: $business-blue;

              i {
                font-size: 18px;
                color:     white;
              }
            }

            span {
              font-size: 14px;
            }
          }

          .add-license-form {
            display: none;
          }

          .add-license-form.active {
            display: block;

            .license-form-title {
              padding:       40px 0 18px 0;
              border-bottom: 1px solid #edeeef;
            }

            form {
              display:    grid;
              row-gap:    20px;
              margin-top: 20px;
            }
          }
        }
      }

      .modal-footer {
        padding: 0;
      }
    }
  }

  .overlay.active {
    display: block;
  }

  // - Forms -
  .col-titles {
    display:       grid;
    column-gap:    20px;
    margin-bottom: 24px;
    font-size:     13px;
    font-weight:   400;
  }

  .field {
    position: relative;
    margin-bottom: 20px;

    input, button.dropdown-toggle {
      width:            100%;
      padding:          0 14px;
      font:             400 13px/38px 'Montserrat', sans-serif;
      text-align:       left;
      color:            #0a2c46;
      background-color: white;
      border:           1px solid #e8e9ea;
      border-radius:    2px;
    }

    button.dropdown-toggle {
      display:         flex;
      align-content:   center;
      justify-content: space-between;

      i.material-icons {
        font-size:   16px;
        line-height: unset;
      }
    }

    .dropdown-menu.show {
      width:      100%;
      margin-top: 0;

      .dropdown-item {
        padding: 0 14px;
        font:    400 13px/36px 'Montserrat', sans-serif;
        color:   #0a2c46;
        cursor:  pointer;
      }
    }

    input:focus, button.dropdown-toggle:focus {
      border:     1px solid $business-blue;
      outline:    none;
      box-shadow: none;
    }

    .dropdown-toggle:after { content: none }

    .show-toggle {
      position:         absolute;
      bottom:           10px;
      right:            20px;
      padding:          0;
      color:            #555;
      background-color: transparent;
      border:           none;
      cursor:           pointer;
    }

    .control {
      position: relative;

      select {
        width: 100%;
        padding: 0 14px;
        font: 400 13px/38px 'Montserrat', sans-serif;
        text-align: left;
        color: #0a2c46;
        background-color: white;
        border: 1px solid #e8e9ea;
        border-radius: 2px;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor: pointer;
      }

      .show-toggle {
        display: grid;
        align-content: center;
        justify-content: center;
        right: 7px;
        top: 11px;
        bottom: unset;
        padding: 3px 8px;
        background-color: white;
        pointer-events: none;
        cursor: pointer;

        div.arrow {
          border-bottom: 2px solid #244152;
          border-right: 2px solid #244152;
          width: 8px;
          height: 8px;
          background-color: white;
          transform: rotate(45deg);
        }
      }
    }
  }

  .check-field {
    display:       flex;
    align-content: center;
    column-gap:    12px;

    label {
      display: block;
      position: relative;
      margin-bottom: 12px;
      padding-left: 28px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ i.material-icons {
      background-color: #0564ac;
      border: 1.75px solid #0564ac;
    }

    i.material-icons {
          position: absolute;
          display: flex;
          align-items: center;
          top: calc(50% - 9px);
          left: 0;
          height: 18px;
          width: 18px;
          background-color: #fff;
          border: 1.75px solid #e8e9ea;
          border-radius: 4px;
          color: #fff;
          font-size: 15px;
          text-align: center;
    }
  }

  .info-request-link {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .modal-btn {
    display: none;
  }
}
