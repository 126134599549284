.get-started-row {
  @include make-row();
  //height: 60vh;
  min-height: 25rem;

  .get-started-content {
    @include make-col-ready();
    background: linear-gradient(20deg, #5098c7 25%, #47a199 75%);
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .get-started-content-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .section-wrap {
        width: 100%;
        padding: 0 1rem;

        .row {
          margin-top: 2rem;

          &:first-child {
            margin-top: 0;
          }

          @include media-breakpoint-up(md) {
            margin-top: 2.5rem;
          }
        }

        .title {
          font-weight: 700;
          font-size: 2rem;

          @include media-breakpoint-up(md) {
            font-size: 2.5rem;
          }
        }

        .step-wrapper {
          @include make-col-ready();
          padding-top: 1rem;

          .step-label {
            display: inline-block;
            font-size: 1.5rem;
            min-width: 1rem;
            font-weight: 700;
            position: relative;
            float: left;

            &::after {
              position: absolute;
              content: '';
              height: 2px;
              bottom: -0.25rem;
              margin: 0 auto;
              left: 0;
              right: 0;
              width: 100%;
              background: #fff;

              @include media-breakpoint-up(md) {
                bottom: -0.5rem;
              }
            }

            @include media-breakpoint-up(md) {
              font-size: 2rem;
              min-width: 1.25rem;
              float: none;
            }
          }

          .step-content {
            padding-top: 0.5rem;
            font-size: 0.8rem;
            line-height: 1.5rem;
            float: left;
            padding-left: 1rem;

            @include media-breakpoint-up(md) {
              float: none;
              padding-left: 0;
            }
          }

          @include media-breakpoint-up(md) {
            @include make-col(4);
            padding-top: 0;
          }
        }

        .action-col {
          @include make-col-ready();
          text-align: center;

          @include media-breakpoint-up(md) {
            @include make-col(6);
            text-align: left;
          }
        }
        .progress-col {
          @extend .action-col;
          padding-top: 1rem;

          @include media-breakpoint-up(md) {
            text-align: right;
            padding-top: 0;
          }
        }

        button {
          border-radius: 3px;
          border: none;
          padding: 0.8rem 1.5rem;
          background: #fff;
          color: #2864a7;
          cursor: pointer;
          box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
          transition: all 0.25s ease-in;

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.30);
          }
        }

        @include media-breakpoint-up(md) {
          padding: 0 2rem;
        }
      }
    }

    @include media-breakpoint-up(md) {
      @include make-col(7);
      background: linear-gradient(45deg, #5098c7 25%, #47a199 50%);
    }
  }

  .get-started-image {
    @include make-col-ready();
    display: none;
    padding: 0;

    img {
      width: 100%;
      object-fit: cover;
    }

    @include media-breakpoint-up(md) {
      @include make-col(5);
      display: flex;
    }
  }
}

.course-finder-wrap {
  background: url('../Magento_Theme/images/img_bg_profession.png') no-repeat center center fixed #eee;
  background-size: cover;

  .course-finder-row {
    @include make-row();
    padding-top: 3rem;
    padding-bottom: 3rem;

    .course-finder-col {
      @include make-col-ready();

      .course-finder {
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
        padding: 2rem;

        .description {
          text-align: center;
          margin-bottom: 2rem;
          font-size: 1.25rem;
          padding: 0 1rem;

          @include media-breakpoint-up(md) {
            font-size: 1.5rem;
            padding: 0 2rem;
          }
        }

        .finder-options {
          padding: 0 1rem;

          @include media-breakpoint-up(md) {
            padding: 0 2rem;
            line-height: 3rem;
          }
        }

        .finder-text {
          display: none;

          @include media-breakpoint-up(md) {
            display: inline;
          }
        }

        .finder-select {
          display: block;
          margin-top: 1rem;

          &:first-child {
            margin-top: 0;
          }

          select {
            width: 100%;
          }

          @include media-breakpoint-up(md) {
            display: inline;
            margin-top: 0;

            select {
              width: auto;
            }
          }
        }

        .button-wrap {
          text-align: center;
          margin-top: 1rem;

          button {
            border-radius: 3px;
            padding: 0.8rem 1.5rem;
            background: #fff;
            color: #2864a7;
            cursor: pointer;
            border: 2px solid #2864a7;
            transition: all 0.25s ease-in;

            &:hover {
              background: #2864a7;
              color: #fff;
            }

            &:disabled {
              background: #fff;
              border: 2px solid #e9e9e9;
              color: #a1a1a1;
              cursor: default;

              &:hover {
                background: #fff;
                color: #a1a1a1;
                cursor: default;
              }
            }
          }

          @include media-breakpoint-up(md) {
            margin-top: 2rem;
          }
        }
      }

      @include media-breakpoint-up(md) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
  }
}

.ce-pro-membership-row {
  @include make-row();
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #fff;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 2rem;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .tag-wrap {
    @include make-col-ready();
    text-align: center;

    .tag {
      background: #47a199;
      color: #fff;
      font-size: 0.8rem;
      margin-bottom: 1rem;
      display: inline-block;
      padding: 0 0.5rem;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .pricing-wrap {
    @include make-col-ready();
    text-align: center;

    .description {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .pricing {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .note {
      font-size: 0.8rem;
      line-height: 1.25rem;
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
      text-align: left;
    }
  }

  .benefits-wrap {
    @include make-col-ready();
    margin-top: 2rem;

    ul {
      list-style: none;
      margin: 1rem 0 0;
      padding: 0 0 0 1rem;

      li {
        font-size: 0.8rem;

        &::before {
          content: "\25CB";
          color: #8ba049;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
          margin-right: 0.5rem;
        }
      }
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
      margin-top: 0;
    }
  }

  .button-wrap {
    @include make-col-ready();
    text-align: center;
    margin-top: 1rem;

    button {
      border-radius: 3px;
      border: none;
      padding: 0.8rem 1.5rem;
      background: #8ba049;
      color: #fff;
      cursor: pointer;
      box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
      transition: all 0.25s ease-in;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.30);
      }
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.on-track-wrap {
  background: #f3fafa;
  padding: 3rem 0;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 2rem;
  }

  .card {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    padding: 2rem;
    text-align: center;
    margin-bottom: 1rem;

    .card-title {
      font-weight: 700;
      margin-top: 1rem;
    }

    .card-description {
      margin-top: 1rem;
      font-size: 0.8rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.association-wrap {
  background: #fcfefe;
  padding: 3rem 0;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding-bottom: 2rem;
  }

  .card {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    padding: 2rem;
    text-align: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}


/* CE(Retired) Styles
   ========================================================================== */
@media (prefers-reduced-motion:no-preference){
    :root {
        scroll-behavior:smooth;
    }
}

.section__title,
.article__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #485865;
}

.section__subtitle,
.article__subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 2px;
    color: #009796;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .section__title,
    .article__title {
        font-size: 25px;
        line-height: 32px;
    }

    .section__subtitle,
    .article__subtitle {
        font-size: 14px;
        line-height: 18px;
    }
}

[data-content-type="row"] {

    .row-full-width-inner {
        @include make-container();
        @include make-container-max-widths();
    }
}


.section--ce-courses {

    .container {
        scroll-margin-top: 230px;

        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
        }
    }

    .ce-brand {

        @media (max-width: 767px) {
            & + .ce-brand {
                margin-top: 20px;
            }
        }

        @media (min-width: 768px) {
            flex: 0 0 48.5%;
        }
    }

}



// Brand article
.ce-brand {
    background-color: #F8F8F8;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, .19);
    border-top: 16px #00AF9F solid;

    &--nurse-dot-com {
        border-top-color: #1979C3;

        .article__subtitle {
            color: #1979C3;
        }

        .btn {
            border-radius: 100px;
        }

        .ce-brand__logo {
            & > img {
                max-width: 250px;

                @include media-breakpoint-down(lg) {
                    max-width: 220px;
                }
            }
        }
    }

    .article__content {

        padding: 35px;
    }

    header {

        margin-bottom: 25px;
    }

    .btn {
        margin-top: 15px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
    }

    strong {
        font-weight: 600;
        color: #485865;
    }
}

.ce-brand__logo {
    background-color: #fff;
    margin-bottom: 0;
    padding: 30px 20px;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 130px;
    }

    & > img {
        width: 100%;
        max-width: 400px;
        height: auto;

        @include media-breakpoint-down(xl) {
            width: 100%;
            max-width: 350px;
            height: auto;
        }

        @include media-breakpoint-down(lg) {
            max-width: 280px;
        }
    }
}

// Homepage Login Section
.section--login {
    .section__content {
        padding-left: 10px;
        padding-right: 10px !important; /* Overriding PB inline styles */
        line-height: 24px;

        @media (min-width: 768px) {
            max-width: 80%;
            margin: 0 auto;
        }

    }

    .btn-primary {
        font-size: 15px;
    }
}
