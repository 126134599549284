//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-slide__first__indent-left: 44px;
$modal-slide-mobile__background-color: #F0F0F0;
$modal-overlay__background-color: #E0E0E0;

$modal-action-close__color: #0564ac;
$modal-action-close__font-size: 32px;
$modal-action-close__hover__color: #FFF;

$modal-slide-action-close__padding: 0 0;

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
  .action-close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    border: 1px solid #0564ac;
    border-radius: 50%;
    background-color: #FFF;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 0.8125rem;
    line-height: 1;
    color: #0564ac;
    font-weight: 600;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: #0564ac;
      color: #FFF;
    }
    &:before {
      content: '+';
      display: inline-block;
      transform: rotate(45deg);
    }

    &:hover {
      &:before {
        color: $modal-action-close__hover__color;
      }
    }

    span {
      display: none;
    }
  }
}

.modal-custom {
  .action-close {
    margin: 1rem;
  }
}

.modal-popup {
  pointer-events: none;

  .modal-title {
    border-bottom: 1px solid #CCC;
    font-weight: 500;
    padding-bottom: 0.75rem;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
  }

  .action-close {
    padding: 0.75rem;
  }
}

.modal-slide {
  .action-close {
    padding: 0.25rem 0.5rem;
  }

  .page-main-actions {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.modals-overlay {
  opacity: 0.7;
}

body {
  &._has-modal-custom {
    .modal-custom-overlay {
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 99;
    }
  }
}


//
//  Mobile
//  _____________________________________________

.custom-slide {
  @include lib-modal;

  &._show {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-inner-wrap {
    background-color: $modal-slide-mobile__background-color;
    box-sizing: border-box;
    height: auto;
    min-height: 100%;
  }
}

body {
  &._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw;

    .modal-custom-overlay {
      background-color: $modal-overlay__background-color;
    }
  }
}

.modal-popup {
  pointer-events: auto;
  &.modal-slide {
    .modal-inner-wrap[class] {
      background-color: $modal-slide-mobile__background-color;
    }

    &._inner-scroll {
      &._show {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
      }

      .modal-inner-wrap {
        height: auto;
        @include media-breakpoint-up(md) {
          min-height: unset;
        }
      }
    }
  }

  .modal-title {
    font-size: 1rem;
    font-weight: 600;
  }
}

//
//  Desktop
//  _____________________________________________

@include media-breakpoint-up(md) {
  .modal-popup {
    &.modal-slide {
      .modal-footer {
        border-top: 1px solid #CCC;
        text-align: right;
      }
    }
  }
}
